.home {
    width: 100;
  }
  img {
    width: 100%;
  }
  .tdee {
    margin-top: 70px;
    text-align: center;
    padding: 20px;
  }
  
  .form {
    text-align: left;
    padding: 20px;
  }
  
  .orderLabel {
    font-size: 24px;
  }
  
  .input[type='radio'] {
    margin-right: 10px;
    border: 1px #cccccc solid;
  }
  
  .textReccomended {
    margin-top: 40px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  