.home {
    width: 100;
}
img {
    width: 100%;
}
.status{
    margin-top: 70px;
    text-align: center;
    padding: 20px;
}
.react-calendar {
    border: 0px;
    width: 100%;
}
.react-calendar button{
    border-radius: 35px;
    height: 50px;
}
.react-calendar__tile--active {
    /*background: #89B75C;*/
    background: '#FFF';
}

.text-info {
    margin-top: 20px;
}

.text-info > .p-left {
    text-align: justify;
}

.eventDelivery {
    background: #AED864;
    border-radius: 25px solid #AED864;
    color: #FFF;
}

.eventDeliveryMore {
    background: #FFB540;
    border-radius: 25px solid #FFB540;
    color: #FFF;
}

.numberofdelivery {
    font-size: 10px;
    position: relative;
    right: -10px;
    top: -25px;
}
.ShowDetail {
    text-align: left;
}

.btnStyle {
    font-size: 16px !important;
    background-color: #AED864 !important;
    border: 0px !important;
}

.addressList {
    text-align: left;
    padding-left: 20px;
}