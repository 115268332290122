.home {
    width: 100;
}
img {
    width: 100%;
}
.faq{
    margin-top: 70px;
    text-align: center;
    padding: 20px;
    margin-bottom: 100px;
}

.languageBar {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: #FFF;
    text-align: center;
    padding: 0px;
}

.languageBarMenu {
    background: #FFF;
    padding: 14px 0px;
}

.activeLang {
    background: #aed864;
    color: #FFF;
}

.collapseStyle {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}