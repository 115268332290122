.home {
    width: 100;
}
img {
    width: 100%;
}
.complete{
    margin-top: 70px;
    text-align: center;
    padding: 20px;
}