.home {
  width: 100;
}
img {
  width: 100%;
}
.orders {
  margin-top: 70px;
  text-align: center;
  padding: 20px;
}

.form {
  text-align: left;
  padding: 20px;
}

.orderLabel {
  font-size: 24px;
}

.input[type="radio"] {
  margin-right: 10px;
  border: 1px #cccccc solid;
}

.textReccomended {
  margin-top: 40px;
  font-size: 14px;
  margin-bottom: 20px;
}

.NoteText {
  text-align: left;
  color: #aed864;
  font-size: 14px;
}

.btnStyle {
  font-size: 16px !important;
  background-color: #aed864 !important;
  border: 0px !important;
  margin-top: 24px;
}

.CourseSelection {
  color: #AED864;
}

.inputRadio {
  margin-top: 14px !important;
}

.NoteTextMeal {
  margin-left: 40px;
  text-align: left;
  color: #aed864 !important;
  font-size: 12px;
}

.react-calendar {
  border: 0px;
  width: 100%;
}
.react-calendar button{
  border-radius: 35px;
  height: 50px;
}

.react-calendar__tile--active {
  background: #cccccc !important;
}

/*.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
  background: #FFF !important;
  color: #212529;
}*/

.text-info {
  margin-top: 20px;
}

.text-info > .p-left {
  text-align: justify;
}

.eventDelivery {
  background: #AED864 !important;
  border-radius: 25px solid #AED864 !important;
  color: #FFF !important;
}

.eventDeliveryMore {
  background: #FFB540;
  border-radius: 25px solid #FFB540;
  color: #FFF;
}
