.header {
    position: fixed;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: #FFF;
    margin-bottom: 10px;
    text-align: center;
    padding: 0px;
    z-index: 9999;
}

.headerImg {
    height: 50px;
    width: 50px;
}